import supabase from "../sup";

import React, { useEffect, useState } from "react";

import {
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  Input,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {useNavigate} from "react-router-dom";



function Book() {

    const redirect = useNavigate();
  const [data, setData] = useState(null);
  const [venue, setVenue] = useState(null);
  const [date, setDate] = useState(null);
  const [booked, setBooked] = useState(null);

  const handleChange = (event) => {
    setVenue(event.target.value);
  };

  const putData = async (venue, date, booked) => {
    const { data } = await supabase
      .from("bookingdata")
      .insert([
        {
          BookedBy: booked,
          VenueID: venue,
          BookDate: date,
          approved: false,
          bookid: venue + "##" + date,
          created_at: new Date(),
        },
      ]);
    console.log(data);
    alert("Venue Booked");
    redirect('/')

  };

  useEffect(() => {
    async function fetchData() {
      const { data } = await supabase.from("venuedata").select("*");
      setData(data);
      data.sort((a, b) => a.id - b.id);
    }
    fetchData();
  }, []);

  const Submit = () => {
    if (!venue || !date || !booked) {
      alert("Please fill all the fields");
      return;
    }
    // check if already booked using the bookid
    // if already booked then alert and return
    // else book the venue

    const bookid = venue + "##" + date;
    console.log(bookid);

    async function checkBooked() {
      const { data } = await supabase
        .from("bookingdata")
        .select("*")
        .eq("bookid", bookid);
      if (data.length > 0) {
        alert("Venue already booked");
        return;
      }
      putData(venue, date, booked);
    }
    checkBooked();
    //
    // alert("Are you sure you want to book this venue?" + venue + " on" + date + " by " + booked + "with id" + venue + "##" + date)
    // putData(venue, date, booked);
  };

  return (
    <Box
      sx={{
        width: "100%",
        margin: "auto",
        padding: "20px",
        textAlign: "center",
        flexDirection: "column",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Typography variant="h2" sx={{ marginY: "20px" }}>
        Book
      </Typography>

      {/* id	bigint	int8	

        created_at timestamp with time zone	timestamptz	

        Booked By text	text	

        VenueID smallint	int2	

        BookDate timestamp without time zone	timestamp	

        approved boolean bool	

        bookid text	text */}

      <Select value={venue} onChange={handleChange}>
        {data &&
          data.map((venue) => (
            <MenuItem key={venue.id} value={venue.id}>
              {venue.VName}
            </MenuItem>
          ))}
      </Select>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          value={date}
          onChange={setDate}
          disablePast
          sx={{ margin: "10px" }}
        />
      </LocalizationProvider>
      <Input
        variant="outlined"
        padding="10px"
        margin="10px"
        value={booked}
        onChange={(e) => setBooked(e.target.value)}
        placeholder="Your Name"
      />

      <Button
        variant="contained"
        color="primary"
        onClick={Submit}
        sx={{ margin: "10px" }}
      >
        Book
      </Button>
    </Box>
  );
}

export default Book;
