import React from "react";


function About() {
  return (
    <div>
      <h1>About</h1>
      <p> Made By : </p>

        <p> VB Manihbarathi [RA2211030020026] </p>
        <p> M DHaneshwar [RA2211030020052]</p>
        <p> S Andrew Leo [RA2211030020062]</p>
    </div>
  );
}

export default About;