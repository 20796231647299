import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import App from "./App";
import Book from "./pages/book";
import Dash from "./pages/dash";
import About from "./pages/about";


const router = createBrowserRouter([
  {path: "/",element: <App />,},
  {path: "/book",element: <Book />,},
  {path: "/dashboard",element: <Dash />,},
  {path: "/about",element: <About />,},

]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);