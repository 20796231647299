import { Table, TableCell, TableHead, TableRow , Button } from "@mui/material";
import supabase from "../sup";

import React, { useEffect, useState } from "react";


// show all bookings

function Dash() {

    const [data, setData] = useState(null);

    useEffect(() => {
        async function fetchData() {
            const { data } = await supabase.from("bookingdata").select("*");
            setData(data);
            data.sort((a, b) => a.id - b.id);
        }
        fetchData();
    }, []);

    const approve = async (id) => {
        const { data } = await supabase.from("bookingdata").update({ approved: true }).eq("id", id);
        console.log(data);
        alert("Booking Approved");
        // rerender the page
        window.location.reload();
    }

    const reject = async (id) => {
        // delete the booking
        const { data } = await supabase.from("bookingdata").delete().eq("id", id);
        console.log(data);
        alert("Booking Rejected");
        // rerender the page
        window.location.reload();
    }


    return (
        <div>
            <h1>Bookings</h1>
            <Table>
                <TableHead>
                    <TableCell>BookedBy</TableCell>
                    <TableCell>VenueID</TableCell>
                    <TableCell>BookDate</TableCell>
                    <TableCell>approved</TableCell>
                    <TableCell>bookid</TableCell>
                    <TableCell>created_at</TableCell>
                    <TableCell>Approve</TableCell>
                    <TableCell>Reject</TableCell>
                </TableHead>

                {data && data.map((booking) => (
                    <TableRow key={booking.id}>
                        <TableCell>{booking.BookedBy}</TableCell>
                        <TableCell>{booking.VenueID}</TableCell>
                        <TableCell>{booking.BookDate}</TableCell>
                        <TableCell>{booking.approved.toString()}</TableCell>
                        <TableCell>{booking.bookid}</TableCell>
                        <TableCell>{booking.created_at}</TableCell>
                        <TableCell><Button onClick={() => approve(booking.id)} variant="contained" color="primary">Approve</Button></TableCell>
                        <TableCell><Button onClick={() => reject(booking.id)} variant="contained" color="secondary">Reject</Button></TableCell>
                    </TableRow>
                ))}
            </Table>
        </div>
    );
    
}

export default Dash;