import React, { useEffect } from "react";
import supabase from "../sup";
import { Box, Grid, Typography , Button } from  "@mui/material";
import { useNavigate } from "react-router-dom";

function Home() {
  // get database from supabase

  const [data, setData] = React.useState(null);

  useEffect(() => {
    async function fetchData() {
      const { data } = await supabase.from("venuedata").select("*");
      setData(data);
      data.sort((a, b) => a.id - b.id);
    }
    fetchData();
  }, []);

    const redirect = useNavigate();

  const Bookit = (id) => {
    console.log(id)
    redirect('/book')
  }

  return (
    <Box sx={{width: '100%', margin: 'auto' , padding: '20px'}}>
      <Typography variant="h2" sx={{ marginY: '20px'}}>
        Venues
      </Typography>
      {/* id	VName	VLoc	VIncharge	Vcontact	Vcap	Vfac	Vcom */}
    <Grid container spacing={2} >

        {data && data.map((venue) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={venue.id} sx={{padding: 2 , border : '2px solid black' , borderRadius : '10px' , margin : '10px'}}>
                <Box>
                <Typography variant="h6">{venue.VName}</Typography>
                <Typography variant="body1">{venue.VLoc}</Typography>
                <Typography variant="body1">{venue.VIncharge}</Typography>
                <Typography variant="body1">{venue.Vcontact}</Typography>
                <Typography variant="body1">{venue.Vcap}</Typography>
                <Typography variant="body1">{venue.Vfac}</Typography>
                <Typography variant="body1">{venue.Vcom}</Typography>
                <Button onClick={() => Bookit(venue.id)} variant="contained" color="primary">Book</Button>
                </Box>
            </Grid>
            ))}
    </Grid>
    </Box>
  );
}

export default Home;
